import React, { useState } from "react";
import { useEffect } from "react";
import { fetchLinkPreview } from "helpers/utility";

function LinkPreviewComponent({ link }) {
  const [linkPreviewData, setLinkPreviewData] = useState(null);

  useEffect(() => {
    if (link && !link.includes("localhost")) {
      fetchLinkPreview(link)
        .then((r) => setLinkPreviewData(r))
        .catch((e) => setLinkPreviewData({ error: e.message }));
    }
  }, []);

  return (
    <div
      style={{
        maxWidth: 300,
        textAlign: "left",
        wordWrap: "break-word",
        wordBreak: "break-word",
      }}
    >
      {linkPreviewData && !linkPreviewData.error && (
        <a
          className="link_preview"
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          {linkPreviewData.image && linkPreviewData.image.includes("http") && (
            <img src={linkPreviewData.image} alt="Link Preview" />
          )}
          <div className="ttitle">
            {linkPreviewData.icon && linkPreviewData.icon.includes("http") && (
              <img src={linkPreviewData.icon} alt="Link icon" />
            )}
            <h2 className="text-center">{linkPreviewData.title}</h2>
          </div>
          {linkPreviewData.description && <p>{linkPreviewData.description}</p>}
        </a>
      )}{" "}
      {(!linkPreviewData || linkPreviewData.error) && (
        <>
          {/* <p style={{ margin: 0 }}>Preview: "{linkPreviewData?.error}"</p> */}
          <a href={link}>{link.slice(8, 30) + "..."}</a>
        </>
      )}
    </div>
  );
}

export default LinkPreviewComponent;
